.responseitemquestion {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
  color: rgb(20, 20, 132);
  margin-bottom: 4px;
}

.responseitemanswer { 
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #010101;
  margin-bottom: 20px;
  line-height: 1.5;
  white-space: pre-wrap;
}