.addsuppliermain {
  width: 100%;
}

.addsupplierformwrapper {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
  max-width: 1000px;
}

@media screen and (max-width: 800px) {
  .addsupplierformwrapper {
    margin-left: 30px;
    margin-right: 30px;
  }  
}