.supplierInfoFieldWrapper {
  margin-bottom: 20px;
  padding-left: 15px;
  max-width: 63vh;
  display: flex;
  justify-content: space-between;
}

.supplierInfoFieldHeading {
  font-family: 'Heebo', sans-serif;
  color: #3c3838;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.8rem;
  letter-spacing: 0.02rem;
  margin-right: 70px;
}

.supplierInfoFieldValue {
  font-family: 'Source Sans Pro', sans-serif;
  color: #3c3838;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .supplierInfoFieldSpan {
  min-width: 20px; Needed to align edit text properly to the right. 
} */
