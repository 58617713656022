.initialAssessmentMain {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
  margin: 2rem 0;
  padding: 0 2rem 2rem;
}

.supplierassessmentdirections {
  font-size: 1.1rem;
  /* margin-left: 8px; */
  /* margin-bottom: 8px; */
}

.supplierassessmentdirections p {
  margin: 0;
}

.assessmentgoback {
  font-size: 13px;
  margin-bottom: 20px;
}

.assessmentgoback a {
  color: royalblue;
}
