.supplierpagemain {
  width: 100%;
  margin-bottom: 2rem;
}

.supplierpageeditableswrapper,
.supplierpageassessmentswrapper {
  margin: 20px 25px 100px;
}

.supplierpageassessmentswrapper {
  margin-bottom: 100px;  
}

.supplierpageClimateProfile {
  margin-top: -25px;
}

.supplierpagelinkwrapper {
  margin-bottom: 75px;
  margin-top: 1rem;
}

.link {
  margin-left: 2.5rem;
  color: #6b7dc2;
  /* text-decoration: none; */
}

.supplierpageassessmentstitle {
  margin-left: 25px;
  margin-right: 25px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #5a4e4e;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.switch_container {
  margin: 20px 25px;
  color: #5a4e4e;
}

.switch_text h3 {
  margin: 0 10px 0;
  padding-bottom: 0;
}

.switch_button_container {
  margin: 0 15px;
  padding: 0;
}

.supplierpagesititle {
  margin-left: 25px;
  margin-right: 25px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #5a4e4e;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

