.supplierinfoeditablefieldwrapper {
  margin-bottom: 20px;
  padding-left: 15px;
  max-width: 63vh;
}

.supplierinfoeditablefieldheading {
  font-family: 'Heebo', sans-serif;
  color: #3c3838;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.8rem;
  letter-spacing: 0.02rem;
}

.supplierinfoeditablefieldvalue {
  font-family: 'Source Sans Pro', sans-serif;
  color: #3c3838;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supplierinfoeditablefieldspan {
  min-width: 20px; /* Needed to align edit text properly to the right. */
}

.supplierinfoeditablefieldbutton {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor:pointer;
}

.supplierinfoeditablefieldbuttoninside {
  display: flex;
  flex-direction: row;
}

.supplierinfoeditablefieldpencil {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.supplierinfoeditablefieldtext {
  font-family: 'Source Sans Pro', sans-serif;
  color: #0a8080;
  font-weight: 600;
  text-decoration: underline;
}