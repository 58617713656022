.assessmentresponsemain {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 60px 30px 100px 30px;
}

.assessmentresponsecontentwrapper {
  max-width: 860px;
}
