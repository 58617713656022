.assessmentfieldwrapper {
  margin-bottom: 20px;
  padding-left: 15px;
  max-width: 63vh;
}

.assessmentfieldheading {
  font-family: 'Heebo', sans-serif;
  color: #3c3838;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.8rem;
  letter-spacing: 0.02rem;
}

.assessmentfieldheading a {
  color: #3c3838;
  text-decoration: none;
}

.assessmentfieldvalue {
  font-family: 'Source Sans Pro', sans-serif;
  color: #3c3838;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assessmentfieldspan {
  min-width: 20px; /* Needed to align edit text properly to the right. */
  font-size: 13px;
  color: darkred;
}

.assessmentfieldspanblue {
  min-width: 20px; /* Needed to align edit text properly to the right. */
  font-size: 13px;
  color: royalblue;
}

.assessmentfieldspangreen {
  min-width: 20px; /* Needed to align edit text properly to the right. */
  font-size: 13px;
  color: darkgreen;
}

.assessmentfieldbutton {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.assessmentfieldbuttoninside {
  display: flex;
  flex-direction: row;
}

.assessmentfieldpencil {
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.assessmentfieldtext {
  font-family: 'Source Sans Pro', sans-serif;
  color: #6b7dc2;
  font-weight: 600;
  text-decoration: underline;
}
