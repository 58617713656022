.assesssmentresponseheader {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 25px;
}

.assesssmentresponseheaderback {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 40px;
  color: rgb(91, 35, 71);
}

.assesssmentresponseheaderback a {
  text-decoration: none;
}

