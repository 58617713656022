.delete_supplier_section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: 70vh;
}

.supplier_delete_main_text {
  margin: 1rem 0 0 2.5rem;
  font-weight: bolder;
}

.supplier_delete_sub_text {
  font-weight: normal;
}

.delete_supplier_button {
  margin-top: .7rem;
}