.dashboardwrapper {
  display: flex;
  width: 100%;
}

.dashboardmain {
  width: 100%;
}

.link {
  text-decoration: none;
}