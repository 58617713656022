.loginpagewrapper {
  padding-top: 100px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.loginwidgetcontainer {
  padding: 50px 40px 80px 40px;
  width: 520px;
  color: white;
  background-color: #c9d5cde6;
  border-radius: 8px;
  box-shadow: 2px 8px 10px rgba(200, 195, 195, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.eslogoimg {
  width: 180px;
}

.signintext {
  color: black;
  margin-bottom: 35px;
}

.signintext:hover {
  cursor: pointer;
}

.loginemailfieldlabel {
  display: block;
  margin: 5px 0;
  color: rgb(118, 116, 116);
  font-size: 0.9rem;
  text-transform: uppercase;
}

.loginemailinputfield {
  font-family: 'Public Sans', sans-serif;
  width: 100%;
  border-radius: 5px;
  /* border: 'var(--custom-border)', */
  padding: 8px;
  box-sizing: border-box;
  font-size: 0.9rem;
  background-color: #363333;
  border: #363333;
  /* color: 'var(--custom-color)' */
  margin-bottom: 12px;
  color: white;
  height: 42px;
}

.loginemailinputfield::placeholder {
  color: rgb(196, 195, 195);
}

.loginsendmagiclinkbutton {
  width: 100%;
  color: white;
  background-color: #363333;
  border: #363333;
  border-radius: 5px;
  height: 42px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.sendingmagiclinktext {
  color: black;
}
