.loginpagewrapper {
  font-family: 'Public Sans', sans-serif;
  width: 100%;
  height: 100vh;
  background-image: url('../../images/teafieldmedium.jpg');
  background-size: cover;
}

.earthscopetext {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 60px;
  font-weight: 500;
  position: absolute;
  left: 25px;
  top: 25px;
}

.loginwidgetcontainer {
  position: absolute;
  left: 45vw;
  top: 10vh;
  padding: 45px 63px 60px 55px;
  width: 460px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.signintext {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 22.5px;
  line-height: 1.2;
  color: black;
  margin-bottom: 24px;
}

.newuser {
  margin: 0;
  margin-bottom: 38px;
  color: black;
  font-size: 14px;
  display: block;
  font-weight: 500;
  line-height: 20px;
}

.loginemailfieldlabel {
  color: black;
  font-size: 16px;
  display: block;
  font-size: 14px;
  font-weight: 600;
}

.loginemailinputfield {
  border: none;
  height: 28px;
  width: 100%;
  margin-bottom: 21px;
  border-bottom: 1px solid #d9d9d9;
  font-size: 16px;
  color: #353434;
}

.loginemailinputfield {
  outline: none;
}


/* .loginemailinputfield::placeholder {
  color: rgb(196, 195, 195);
} */
.loginbuttonwrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.loginsendmagiclinkbutton {
  width: 89px;
  height: 33px;
  background-color: #307CC2;
  color: white;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  border-radius: 115px;
  border: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

.loginpagebadcredentials {
  color: maroon;
  font-size: 12px;
}

.sendingmagiclinktext {
  color: black;
}

.signinhrwrapper {
  display: flex;
  justify-content: center;
}

.signinspacer {
  height: 40px;
}

/* .signinhr {
  margin: 30px 0 30px 0;
  width: 100px;
  border-top: 2px solid darkgrey;

} */