.sendassessmentmain {
  width: 100%;
}

.sendassessmentwrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3vw;
}

.sendassessmenth1 {
  margin-top: 150px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 1.2;
  text-align: center;
}

.sendassessmenth2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #252323;
  margin-bottom: 40px;
}

.sendassessmentinput {
  min-width: 500px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #252323;
}

.sendassessmentbuttonwrapper {
  margin-top: 28px;
}

.sendassessmentbutton {
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
  background-color: #8eb88e;
  font-size: 18px;
  padding: 14px 22px;
  border: none;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 50px;
}

.sendassessmentcoverage{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
  max-width: 600px;
}

.sendassessmentcoverage a {
  color: #191934;
}

.sendassessmentfeedback {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  text-align: center;
}

.sendassessmentfeedback > a {
  color: rgb(49, 78, 165);
}

.sendassessmentnomatch {
  color: darkred;
  font-family: 'Heebo', sans-serif;
  font-size: 12px;
  margin-top: 8px;
}

@media screen and (max-width: 600px) {
  .sendassessmenth1 {
    margin-top: 80px;
    font-size: 20px;
  }
  
  .sendassessmenth2 {
    font-size: 17px;
  }
  
  .sendassessmentinput {
    min-width: 94%;
    font-size: 15px;
  }
  
  .sendassessmentbutton {
    font-size: 17px;
    padding: 10px 18px;  
  }
}

@media screen and (max-width: 460px) {
  .sendassessmentwrapper {
    padding: 5vw;
  }
}