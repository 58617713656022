.assessmentheadertitleblock {
  display: flex;
}

.assessmentheadertitle {
  font-size: 4vh;
}

.assessmentheadericon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 5vh;
  margin-left: 5px;
}

.assessmentheadericonbutton {
  background-color: #fcfcfc;;
  border: none;
}