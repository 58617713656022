.greenCheckMark {
    width: 30%;
}

.cardContent {
    text-align: center;
}

.cardH2 {
    font-size: 2rem;
    padding-bottom: .0rem;
    margin: 0;
    font-weight: normal;
}

.cardParagraph {
    padding: 0;
    /* margin: 0; */
    font-size: 1.2rem;
}