@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;300;400;500;600;700&family=Inter:wght@300;400;500;600;700&family=Public+Sans:wght@100;300;400;500;700&family=Roboto:wght@300;400;500;700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

html,
body,
#root,
.content {
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.4
}

.app {
  display: flex;
  position: relative;
}
