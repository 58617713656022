.supplierInfoPageMain {
  width: 100%;
  margin-bottom: 2rem;
}

.supplierInfoContainer {
  margin-top: 20px;
}

.supplierInfoPageWrapper {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 20px;
  margin-bottom: 100px;
}

.supplierPageTitle {
  margin-left: 25px;
  margin-right: 25px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #5a4e4e;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.supplierPageSubtitle {
  margin-left: 25px;
  margin-right: 25px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #5a4e4e;
  text-transform: uppercase;
}

